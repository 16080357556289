<template>
  <div class="dashboard-page">
    <van-nav-bar
      :style="{'padding-top': statusBarHeight + 'px'}"
      class="dashboard-nav"
      title="仪表盘"
      placeholder
      safe-area-inset-top
    />
    <div class="dashboard-box" :style="{height: `calc(100vh - 96px - ${moreHeight}px`}">
      <template v-for="dashboard in dashboardList">
        <div :key="dashboard['id']" class="dashboard-item-box">
          <van-cell
            :title="dashboard['title']"
            size="large"
            @click="clickListItem(dashboard)">
            <template #right-icon>
              <div class="dashboard-cell-icon">
                <van-icon v-if="dashboard['showContent']" name="arrow-up" />
                <van-icon v-else name="arrow-down" />
              </div>
            </template>
          <!-- <div :key="dashboard['id']">
        <van-cell
          :title="dashboard['title']"
          is-link
          size="large"
          :arrow-direction="showContent ? 'up' : 'down'"
          :to="`/dashboard/view/${dashboard['id']}`"> -->
          <!-- <div slot="label">
            时间：{{ dashboard['createdDate'] | moment("YYYY-MM-DD") }}
            作者：{{ dashboard['createdBy'] }}
          </div> -->
          </van-cell>

          <van-grid
            v-if="dashboard['showContent']"
            :border="false"
            :column-num="3"
            :gutter="8"
            class="dashboard-wrapper"
            clickable>
            <van-grid-item v-for="item in dashboard['items']" :key="item.id" @click="()=>{}">
              <div class="dashboard-item">
                <div>
                  {{ item.title }}
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'DashboardIndex',
  components: {
  },
  data () {
    return {
      page: 0,
      loading: false,
      dashboardList: []
    }
  },
  computed: {
    ...mapState({
      dataRegionId: state => state.dataRegion.activeItem.id,
      dataRegionItems: state => state.dataRegion.dataList,
      statusBarHeight: state => state.global.statusBarHeight,
      moreHeight: state => state.global.moreHeight
    })
    // restrictDataRegionId () {
    //   if (this.dataRegionItems.length === 1) {
    //     return this.dataRegionItems[0]['id']
    //   } else if (this.dataRegionItems.length === 0) {
    //     return 1
    //   }
    //   return null
    // }
  },
  methods: {
    getDashboardList() {
      const { page, title } = this
      const params = {
        page,
        title,
        type: 'DASHBOARD',
        dataRegionId: this.dataRegionId
      }
      this.$api.dashboard.getDashboardList(params).then(res => {
        this.dashboardList = res['content'].filter(item => JSON.parse(item['data']).platform === 'mobile')
        this.dashboardList.forEach(item => {
          item['showContent'] = false
        })
        // this.dashboardList[0]['items'] = [{
        //   id: 1,
        //   title: '高管驾驶舱'
        // }, {
        //   id: 2,
        //   title: '收入分析'
        // }, {
        //   id: 3,
        //   title: '成本费用分析'
        // }, {
        //   id: 4,
        //   title: '资产负债分析'
        // }, {
        //   id: 5,
        //   title: '利润表'
        // }, {
        //   id: 6,
        //   title: '资产负债表'
        // }]
        const pagination = { ...this.pagination }
        pagination.page = res['number']
        pagination.total = res['totalPages']
        this.pagination = pagination
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    clickListItem (dashboard) {
      if (dashboard['items']) { // items.length > 0， 判断是否有子目录
        dashboard['showContent'] = !dashboard['showContent']
        this.$forceUpdate()
      } else {
        // 跳转
        this.$router.push(`/dashboard/view/${dashboard['id']}`)
      }
    }
  },
  mounted () {
    this.getDashboardList()
  },
  watch: {
    dataRegionId () {
      this.getDashboardList()
    }
  }
}
</script>
<style lang="less">
.dashboard-page {
  background-image: url("~@/assets/background.png");
  background-size: 100% 40%;
  background-repeat: no-repeat;
  height: 100vh;
  .dashboard-nav {
    height: 40px !important;
    // background-image: url("~@/assets/background.png");
    // background-size: 100%;
    // background-repeat: no-repeat;
    background-color: transparent;
    .van-nav-bar__content {
      height: 40px;
      .van-nav-bar__title {
        font-size: 19px;
        font-weight: bold;
        color: #ffffff;
        font-family: PingFang SC;
      }
    }
      &::after {
        border-bottom-width: 0
      }
  }
  .dashboard-box {
    // height: calc(100vh - 105px);
    overflow: auto;
    padding-top: 6px;
    .dashboard-item-box {
      margin: 0 15px 15px 15px;
      background-color: #ffffff;
      border-radius: 5px;
      .van-cell {
        border-radius: 5px;
        .van-cell__title {
          font-weight: bold;
        }
        .dashboard-cell-icon {
          .van-icon {
            width: 20px;
            height: 20px;
            background-color: #37acdf14;
            border-radius: 50%;
            text-align: center;

            &::before {
              color: #319DE2;
              vertical-align: middle;
            }
          }
        }
      }
      .dashboard-wrapper {
        padding: 5px 10px 16px 15px !important;
        .van-grid-item {
          .van-grid-item__content {
            background-color: #F6F6F6;
            border-radius: 2px;
            .dashboard-item {
              height: 13px;
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
              width: 100%;
              position: relative;
              div {
                text-overflow:ellipsis;
                white-space: nowrap;
                overflow: hidden;
                width: 100%;
                position: absolute;
              }
            }
          }
        }
      }
    }
  }
}
</style>
